export default function(totalMinutes) {
	let hours = Math.floor(totalMinutes / 60);  
	let minutes = totalMinutes % 60;
	
	if (hours && minutes) {
		return (minutes < 10) ? `${hours}:0${minutes}h` : `${hours}:${minutes}h`;
	} else if (hours) {
		return `${hours}h`;
	} else if (minutes) {
		return `${minutes}m`;
	} else {
		return '0:00h';
	}
}